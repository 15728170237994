"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isLocal = exports.getEnv = exports.envError = exports.ensureNonNullEnvName = exports.ensureIsProdNonDebug = exports.PROD_PROFILE_NAME = void 0;

// Generic error meaningless to customers
var envError = function envError() {
  throw new Error('Environment error');
};

exports.envError = envError;
var PROD_PROFILE_NAME = 'prod';
exports.PROD_PROFILE_NAME = PROD_PROFILE_NAME;

var ensureNonNullEnvName = function ensureNonNullEnvName(envName) {
  if (!envName) {
    envError();
  }

  return envName;
};

exports.ensureNonNullEnvName = ensureNonNullEnvName;

var ensureIsProdNonDebug = function ensureIsProdNonDebug(isEnvDebug, envName) {
  if (isEnvDebug) {
    if (envName === PROD_PROFILE_NAME) {
      envError();
    }
  }
};

exports.ensureIsProdNonDebug = ensureIsProdNonDebug;
var isLocal = process.env.NODE_ENV === 'development'
/* Is this defined somewhere ? */
; // Can be null when pre-rendering :'(

exports.isLocal = isLocal;

var getEnv = function getEnv(profileNameProvider, local, dev, demo, prod) {
  if (isLocal) {
    return local;
  } // else


  var profileName = profileNameProvider();

  if (!profileName) {
    return null;
  }

  switch (profileName) {
    case 'dev':
      return dev;

    case 'demo':
      return demo;

    case 'prod':
      return prod;

    default:
      return null;
  }
}; // export const notFound = <T>(envName: string): T => {
//     throw new Error(`Unknown config : '${envName}'`);
// };


exports.getEnv = getEnv;