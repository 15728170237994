import IEnvConfig from "./IEnvConfig";
import LocalEnv from "./LocalEnv";
import DevEnv from "./DevEnv";
import ProdEnv from "./ProdEnv";
import {getEnv, ensureNonNullEnvName, envError} from 'kether-shared-lib/Utils/EnvUtils';

const currentConfigO: IEnvConfig | null =
    getEnv(() => ensureNonNullEnvName(process.env.REACT_APP_ENV_NAME), LocalEnv, DevEnv, null /* DemoConfig */, ProdEnv);

const currentConfigEnv: IEnvConfig = !!currentConfigO ? currentConfigO : envError();

export default currentConfigEnv;
