import axios from 'axios';
import {useEffect, useState} from 'react';
import Loader from "kether-shared-lib/Loader";
import TopBar from 'kether-shared-lib/TopBar/TopBar';
import FLink from 'factor-lib/FLink';

import CurrentEnv from "./Envs/CurrentEnv";

export interface ICompanyDataResponse {
    score: number;
}

const App = (

) => {
    const [companyScore, setCompanyScore] = useState<number | null>(null);
    useEffect(() => {
        axios.get<ICompanyDataResponse>(
            `${CurrentEnv.baseBackendUrl}/companiesInfos?sirenInput=111111111`
        )
            .then((r) => setCompanyScore(r.data.score))
    }, [])
    return (
        <div>
            <TopBar logoClick={null}
                    leftExtra={
                        <div>
                            <FLink id='our-services'
                                   text='Nos services'
                                   action={{
                                       clickHandler: undefined,
                                       href: undefined /* TODO */
                                   } }/>
                        </div>
                    }
                    title={null}
                    rightMenu={null} />
            {!!companyScore
                ? `Dimpl Score : ${companyScore}`
                : <Loader/>
            }
        </div>
    );
};

export default App;
